<template>
    <div class="saleTable freight">
        <div class="table">
            <table>
                <tbody>
                    <tr>
                        <th>基础运费（元/m³):</th>
                        <td>
                            <input
                                type="text"
                                v-model="tabPageData[0].price"
                                placeholder="请输入运输费..."
                                @change="preserveFreight()"
                            >
                        </td>
                        <th>基础公里数（km）</th>
                        <td>
                            <input
                                type="text"
                                v-model="tabPageData[0].mileage"
                                placeholder="请输入公里数..."
                                @change="preserveFreight()"
                            >
                        </td>
                        <th>超出运距费用（元/km·m³）</th>
                        <td>
                            <input
                                type="text"
                                v-model="tabPageData[0].price_over_haul"
                                placeholder="请输入运输费用..."
                                @change="preserveFreight()"
                            >
                        </td>
                    </tr>
                    <tr>
                        <th rowspan="2">
                            费用补充模式：
                        </th>
                        <td style="padding-left:0.2rem;">
                            补方
                        </td>
                        <td colspan="4" class="amountMoney" style="padding-left:0.2rem;">
                            每车不足
                            <input
                                type="text"
                                v-model="tabPageData[0].bv_volumn_less"
                                placeholder="请输入..."
                                @change="preserveFreight()"
                            >
                            m³，按
                            <input
                                type="text"
                                v-model="tabPageData[0].bv_volumn_add"
                                placeholder="请输入..."
                                @change="preserveFreight()"
                            >
                            m³收取运费。
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-left:0.2rem;">
                            补固定金额
                        </td>
                        <td colspan="4" class="amountMoney" style="padding-left:0.2rem;">
                            单次方不足
                            <input
                                type="text"
                                v-model="tabPageData[0].bp_volumn_less"
                                placeholder="请输入..."
                                @change="preserveFreight()"
                            >
                            m³，按实际方量价格结算后加收
                            <input
                                type="text"
                                v-model="tabPageData[0].bp_price_add"
                                placeholder="请输入..."
                                @change="preserveFreight()"
                            >
                            元。
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    //  销售价格管理-运费
    name: 'freight',
    components: {},
    props: {
        currentData: {
            type: Object,
        },
    },
    data() {
        return {
            // 切换数据新ID
            newAreaid: '',
            isRegions: false,
            // 配送费配置列表数据
            tabPageData: [
                {
                    price_id: '',
                    area_id: 0,
                    price: 0,
                    mileage: 0,
                    price_over_haul: 0,
                    bp_volumn_less: 0,
                    bp_price_add: 0,
                    bv_volumn_less: 0,
                    bv_volumn_add: 0,
                },
            ],

        };
    },
    watch: {
        currentData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                this.tabPageData = [
                    {
                        price_id: '',
                        area_id: 0,
                        price: 0,
                        mileage: 0,
                        price_over_haul: 0,
                        bp_volumn_less: 0,
                        bp_price_add: 0,
                        bv_volumn_less: 0,
                        bv_volumn_add: 0,
                    },
                ];
                this.isRegions = newVal.isRegion;
                if (newVal.isRegion) {
                    this.newAreaid = newVal.areaid;
                } else {
                    this.newAreaid = 0;
                }
                const initData = newVal.initData;
                if (newVal.initData && initData.SalePriceInfo) {
                    if (initData.SalePriceInfo !== 'null' && initData.SalePriceInfo !== null) {
                        this.price_id = initData.SalePriceInfo.id;
                        if (initData.SalePriceInfo.is_area) {
                            if (initData.AreaList !== 'null' && initData.AreaList !== null) {
                                this.area_id = initData.AreaList[0].area_id;
                            }
                        } else {
                            this.area_id = 0;
                        }
                    }
                }
                // 获取升降标号列表数据
                this.getFreight();
            },
        },
    },
    computed: {},
    methods: {
        // 获取配送费配置列表
        getFreight() {
            let areaIds;
            if (this.isRegions) {
                areaIds = this.newAreaid ? this.newAreaid : this.area_id;
            } else {
                areaIds = 0;
            }
            if (this.currentData.historyId === '') {return;}
            this.$axios
                // .get('/interfaceApi/sale/price_config/get_price_carriage/' + this.currentData.historyId + '/' + this.tabPageData[0].area_id)
                .get('/interfaceApi/sale/price_config/get_price_carriage/' + this.currentData.historyId + '/' + areaIds)
                .then(res => {
                    if (res.length > 0) {
                        // 配送费配置列表数据
                        this.tabPageData = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存运费
        preserveFreight() {
            let areaIds;
            if (this.isRegions) {
                areaIds = this.newAreaid ? this.newAreaid : this.area_id;
            } else {
                areaIds = 0;
            }
            this.tabPageData[0].price_id = this.currentData.historyId;
            this.tabPageData[0].area_id = areaIds;
            // tabPageData
            this.$axios
                .put('/interfaceApi/sale/price_config/modify_price_carriage', this.tabPageData[0])
                .then(res => {
                    if (res) {
                        this.$message({
                            message: '修改成功',
                            type: 'success',
                        });
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        // 期数id,区域id赋值
        // if (this.initData.SalePriceInfo) {
        //     if (this.initData.SalePriceInfo !== 'null' && this.initData.SalePriceInfo !== null) {
        //         this.price_id = this.initData.SalePriceInfo.id;
        //         if (this.initData.SalePriceInfo.is_area) {
        //             if (this.initData.AreaList !== 'null' && this.initData.AreaList !== null) {
        //                 this.area_id = this.initData.AreaList[0].area_id;
        //             }
        //         }
        //     }
        // }
        if (this.currentData.initData.SalePriceInfo) {
            if (this.currentData.initData.SalePriceInfo !== 'null' && this.currentData.initData.SalePriceInfo !== null) {
                this.price_id = this.currentData.initData.SalePriceInfo.id;
                if (this.currentData.initData.SalePriceInfo.is_area) {
                    if (this.currentData.initData.AreaList !== 'null' && this.currentData.initData.AreaList !== null) {
                        this.area_id = this.currentData.initData.AreaList[0].area_id;
                    }
                }
            }
        }
        // 获取配送费配置列表
        // this.getFreight();
    },
    mounted() { },
};
</script>
<style lang="stylus" scoped>
.freight
    .table
        width 100%
        height 100%
        border 1px solid #d7d7d7
        border-radius 8px
        overflow hidden
        table
            width calc(100% + 2px)
            margin-top -1px
            margin-left -1px
            border-collapse collapse
            th,td
                height 0.6rem
                border 1px solid #D7D7D7
                font-size 0.18rem
            th
                background #EDF0F5
                color #022782
                font-weight 400
            td
                font-size 0.18rem
                color #333333
                input
                    padding-left 0.2rem
                    width 100%
                    height 100%
                    border none
                    line-height 0.6rem
                    font-size 0.18rem
                    color #333333
                    &::placeholder
                        font-size 0.18rem
                        color #C6C6C6
                &.amountMoney
                    input
                        width 2rem
                        height 0.32rem
                        border 1px solid #D7D7D7
                        border-radius 2px
                        0.3rem

</style>